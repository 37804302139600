<template>
  <el-main class="rh-medform">
    <form-contratcreate :routes="contRoutes" :ismeddetail="true" />
  </el-main>
</template>
<script>
import FormContratcreate from "@/components/rh/formulaires/FormContratcreate";

export default {
  components: { "form-contratcreate": FormContratcreate },
  computed: {
    contRoutes() {
      return {
        toedit: {
          name: "med_contrats_edit",
          params: {
            idmed: this.$route.params.idmed,
            idcont: this.$store.state.contl.contratLastCreatedId,
          },
        },
        tolist: {
          name: "med_contrats",
          params: {
            idmed: this.$route.params.idmed,
          },
        },
      };
    },
  },
};
</script>
